<template>
  <div class="units-modify">
    <v-container class="units-modify__container" fluid>
      <entity-modify-header class="mb-10" :title="title" />

      <div class="units-tabs">
        <form-builder
          v-if="!isLoading"
          :initial-data="initialData"
          is-tabs
          :tabs="tabs"
          :active-tab="tab"
          @submit="submit"
          @change-tab="changeTab"
        >
          <template #footer="{ valid }">
            <v-row class="footer-row mt-4">
              <v-col>
                <v-btn v-if="tab >= 1" color="secondary" block class="primary--text" @click="changeTab(tab - 1)">
                  {{ $t('button.back') }}
                </v-btn>
                <v-btn v-else color="secondary" block class="primary--text" :to="backRoute">{{
                  $t('button.cancel')
                }}</v-btn>
              </v-col>
              <v-col>
                <v-btn v-if="tab < tabs.length - 1" type="button" color="primary" block @click.prevent="changeTab">
                  {{ $t('button.continue') }}
                </v-btn>
                <v-btn v-else :disabled="!valid" type="submit" color="primary" block>{{ submitButtonText }}</v-btn>
              </v-col>
            </v-row>
          </template>
        </form-builder>
      </div>
    </v-container>
  </div>
</template>

<script>
// Services
import realtyService from '@/services/realty';
import notificationService from '@/services/notification';
import { normalizeUnit } from '@/services/serializations';
import analyticsService from '@/services/analytics';

// Schema
import { detailsSchema, detailsSchemaEdit, addressSchema, areasSchema } from '@/schemas/unit.schema';

// Components
import EntityModifyHeader from '@/components/EntityModifyHeader.vue';
import FormBuilder from '@/components/schema/FormBuilder.vue';

// Utils
import { createModelData } from '@/schemas/createModelData';

// Constants
import { PROJECTS, UNITS_DETAILED } from '@/constants/routes';
import { TAB_DETAILS, TAB_ADDRESS, TAB_AREAS } from '@/constants/tabs';
import { CREATE_UNIT, EDIT_UNIT } from '@/constants/analyticsActions';

export default {
  name: 'UnitsEdit',

  components: { EntityModifyHeader, FormBuilder },

  props: {
    isEdit: { type: Boolean, default: false },
  },

  data() {
    return {
      isLoading: false,
      initialData: createModelData([...detailsSchema, ...addressSchema, ...areasSchema]),
      schemaList: [this.isEdit ? detailsSchemaEdit() : detailsSchema, addressSchema, areasSchema],
      editSchemaName: null,
      tab: 0,
      tabs: [
        {
          label: 'tab.details',
          tab: TAB_DETAILS,
          schema: this.isEdit ? detailsSchemaEdit() : detailsSchema,
        },
        {
          label: 'tab.address',
          tab: TAB_ADDRESS,
          schema: addressSchema,
        },
        {
          label: 'tab.areas',
          tab: TAB_AREAS,
          schema: areasSchema,
        },
      ],
    };
  },

  computed: {
    submitButtonText() {
      return this.$t(this.isEdit ? 'button.edit_unit' : 'button.add_unit');
    },

    backRoute() {
      return this.isEdit
        ? { name: UNITS_DETAILED, query: { id: this.$route.params.id } }
        : { name: PROJECTS, query: { page: this.$route.query?.prevPage || 1 } };
    },

    title() {
      return this.isEdit ? this.$t('unit.edit_unit') : this.$t('unit.create_unit');
    },

    schema() {
      return this.schemaList[this.tab];
    },
  },

  mounted() {
    if (this.isEdit) {
      this.isLoading = true;
      realtyService
        .getUnitById(this.$route.params.id)
        .then(unit => {
          this.initialData = normalizeUnit(unit);
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  },

  methods: {
    submit(data) {
      const saveUnit = this.isEdit ? realtyService.updateUnit : realtyService.createUnit;

      if (this.$options.notificationItem) {
        notificationService.remove(this.$options.notificationItem);
      }

      this.isLoading = true;
      analyticsService.track(this.isEdit ? EDIT_UNIT : CREATE_UNIT);

      const bodyRequest = this.createBodyRequest(data);

      saveUnit(bodyRequest, data.id)
        .then(unit => {
          const message = this.isEdit ? this.$t('unit.unit_edited') : this.$t('unit.unit_added');
          notificationService.success(message, 2000);

          this.$router.push({ name: UNITS_DETAILED, params: { id: unit?.id || this.$route.params.id } });
        })
        .catch(error => {
          this.initialData = data;

          const keys = Object.keys(error?.response?.data);

          if (keys.length) {
            for (let i = 0; i < keys.length; i += 1) {
              const key = keys[i];
              const errorText = this.getErrorText(error.response.data, key);
              this.$options.notificationItem = notificationService.error(errorText);
            }
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    createBodyRequest(data) {
      const clients = [];

      if (data.status === 'sold' || data.status === 'rented') {
        if (data?.owner?.id) {
          clients.push({
            client: data.owner.id,
            clientType: 'owner',
            salesContractDate: data.salesContractDate,
            rentalPeriodStartDate: null,
            rentalPeriodEndDate: null,
          });
        }

        if (data.status === 'rented' && data.tenant && data.rentalPeriodDate) {
          const splitPeriod = data.rentalPeriodDate.split(' - ');

          clients.push({
            client: data.tenant.id,
            clientType: 'tenant',
            salesContractDate: null,
            rentalPeriodStartDate: splitPeriod[0],
            rentalPeriodEndDate: splitPeriod[1],
          });
        }
      }

      const newInventory = data.inventory.map((item, index) => {
        const newItem = { ...item };

        newItem.orderingValue = index;
        newItem.images = newItem.images?.map((image, imageIndex) => {
          const newImage = { ...image };
          delete newImage.url;

          newImage.orderingValue = imageIndex;
          return newImage;
        });

        return newItem;
      });

      return {
        ...data,
        building: data.building.id,
        status: data.status,
        header: data.header,
        name: data.name,
        propertyType: data.propertyType.value,
        goalRentCost: data.goalRentCost,
        numberOfBedrooms: data.numberOfBedrooms,
        numberOfBaths: data.numberOfBaths,
        isRoofGarden: data.isRoofGarden,
        parking: data.parking.value,
        isSwimmingPool: data.isSwimmingPool,
        parkingNumber: data.parkingNumber,
        internetLogin: data.internetLogin,
        internetPassword: data.internetPassword,
        photos: data.photos,
        clients,
        inventory: newInventory,
        address: data.address,
        addressNumber: data.addressNumber,
        floor: data.floor || null,
        number: data.number,
        subwayStation: data.subwayStation,
        internalArea: data.internalArea,
        totalBuildableArea: data.totalBuildableArea,
        coveredVerandas: data.coveredVerandas ? data.coveredVerandas : 0,
        uncoveredVerandas: data.uncoveredVerandas ? data.uncoveredVerandas : 0,
        lowerLevel: data.lowerLevel ? data.lowerLevel : 0,
        storage: data.storage ? data.storage : 0,
        plot: data.plot ? data.plot : 0,
      };
    },
    getErrorText(data, key) {
      return Array.isArray(data[key]) ? data[key][0] : data[key];
    },
    changeTab(newTab) {
      this.tab = typeof newTab === 'number' ? newTab : this.tab + 1;
    },
  },

  notificationItem: null,
  detailsSchema,
  detailsSchemaEdit,
  addressSchema,
  areasSchema,
};
</script>

<style lang="scss">
.units-modify {
  &__container {
    max-width: 548px !important;
  }
}

.units-tabs {
  .footer-row {
    display: inline-flex;

    .col {
      width: 266px;
    }
  }
}
</style>
